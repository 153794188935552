<template>
  <div>
      <b-modal
      @show="opened"
    ref="add_type"
    id="add_type"
    title="Добавить"
    size="lg"
    :no-close-on-backdrop = "false"
    >
        <b-row>
           <b-col>
              <b-form-group>
                <label>Наименование</label>
                    <b-form-input 
                    id="basicInput" 
                    placeholder="Наименование"
                    
                    />
              </b-form-group>
           </b-col>
           <b-col>
              <label>Сумма</label>
                <b-form-input placeholder="Сумма"></b-form-input>
           </b-col>
           <b-col>
            <label>Тип промокод</label>
            <v-select
            placeholder="Тип промокод"
            :options="type_promo"
            />
           </b-col>
        </b-row>
        <b-row>
           <b-col>
            <label>Клиентский</label>
            <v-select
            placeholder="Клиентский"
            />
            <!-- :options="client_promo" -->
           </b-col>
           <b-col>
            <label>Водительский</label>
            <v-select
            placeholder="Водительский"
            />
            <!-- :options="driver_promo" -->
           </b-col>
           <b-col>
            <label>Скидка</label>
            <v-select
            placeholder="Скидка"
            />
            <!-- :options="discount_promo" -->
           </b-col>
        </b-row>
        <b-row>
            <b-col>
            <label>Виды начислений</label>
            <v-select
            placeholder="Виды начислений"
            />
            <!-- :options="typesof_charges" -->
            </b-col>
            <b-col>
                <label>Организация</label>
                <b-input
                placeholder="Организация"
                />
            </b-col>
        </b-row>
        <template #modal-footer>
          <button 
          class="btn btn-primary"
          >Сохранить
          </button>

          <b-button>
            Отмена
          </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components:{
    vSelect,
    ToastificationContent
  },
  data(){
    return {
      type_promo:[],
    }
  },
methods: {
  opened(){ 
    this.$http
    .get('types-promocodes')
    .then((res) => {
      this.type_promo = res.data.map(item => item.name);
      console.log(this.type_promo, 'test');
    })
  }
},

}

</script>

<style>

</style>