<template>
    <div>
      <b-modal
      ref="block_type"
      id="block_type"
      title="Блокирования промокода"
      size="sm"
      :no-close-on-backdrop = "false"
      >
      <div>
          <label class="label1">Предупреждение!</label>
      </div>
          <br>
      <div>
          <label class="label2">Вы действительно хотите блокировать этот промокод.</label>
      </div>
      <template #modal-footer>
              <button class="btn btn-primary"
              @click="BlockTypeId"
              >
                  Блокировать
              </button>
              <b-button
              >
                  Отмена
              </b-button>
          </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    methods:{
        BlockTypeId(){
            console.log('click');
        }
    }
  }
  </script>
  
  <style scoped>
  .label1{
      display: flex;
      width: 100%;    
      justify-content: center;
      font-size: 25px;
      font-weight: 600;
  }
  .label2{
      display: flex;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
  }
  </style>