<template>
    <div>
        <b-modal
      ref="edit_type"
      id="edit_type"
      title="Изменить тип промокода"
      size="md"
      :no-close-on-backdrop = "false"
      >
          <b-row>
             <b-col>
                <b-form-group>
                  <label>ID</label>
                      <b-form-input 
                      id="basicInput" 
                      placeholder="ID"
                      v-model="editType.types_promocodes_id "
                      />
                </b-form-group>
             </b-col>
             <b-col>
                <label>Наименование</label>
                  <b-form-input 
                  placeholder="Наименование"
                  v-model="editType.name"
                  />
             </b-col>
             <b-col style="margin-left: 15px; margin-top: 20px;" class="custom-control custom-checkbox">
                  <input type="checkbox" v-model="editType.is_active" class="custom-control-input" id="customCheck1"/>
                  <label class="custom-control-label" for="customCheck1">Статус</label>
              </b-col>
          </b-row>
        <template #modal-footer>
            <button 
            @click="editTypePromo()"
            class="btn btn-primary"
            >
              Изменить
            </button>
            <b-button
            @click="closeModal()"
            >
              Отмена
            </b-button>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import vSelect from 'vue-select'
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
  export default {
    components:{
      vSelect,
      ToastificationContent
    },
    data(){
      return {
          editType:{
            types_promocodes_id:'',
            name:'',
            is_active: false 
          }
      }
    },
    methods:{
      editTypePromo(){
        this.$http
        .patch(`types-promocodes/${this.editType.types_promocodes_id}`,this.editType)
        .then( res => {
          console.log(res.data , 'testType');
          this.$toast({
            component:ToastificationContent,
            position: 'top-right',
              props : {
                  title: 'Успех!',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `Промокод изменен`
              }
          })
          this.closeModal()
          .catch( err => {
            console.log(res.data , 'errorr');
            this.$toast({
              props: {
                title: 'Ошибка!',
                icon: 'XIcon',
                variant: 'danger',
                text: `Произошла ошибка`
              }
            })
          })
        })
      },
      closeModal(){
        this.$bvModal.hide('ModalEditType')
        this.editType = {
          types_promocodes_id:'',
          name:'',
          is_active: false 
        }
      }
    }
  }
  </script>
  
  <style>
  
  </style>  