<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
          <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
                  striped
                  small
                  id="myTabless"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :fields="field"
                  :items="item"
                  @row-contextmenu="handleContextMenuEvent"
                  head-row-variant="secondary"
            />
          </b-card>
            <vue-context ref="menu">
              <li>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.add_type
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                <span class="ml-75">Добавить</span>
                </b-link>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.edit_type
                >
                  <feather-icon
                    icon="Edit3Icon"
                  />
                <span class="ml-75">Изменить</span>
                </b-link>
                <b-link
                class="d-flex align-items-center"
                v-b-modal.block_type
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                <span class="ml-75">Блокировать</span>
                </b-link>
              </li>
            </vue-context>
        </b-col>
      </b-row>
    </div>
    <modal-edit-type></modal-edit-type>
  <modal-type-promo></modal-type-promo>
  <modal-add-type ref="ModalAddType"></modal-add-type>
  <modal-block-type></modal-block-type>
  </div>
</template>

<script>
  import ModalEditType from '@/views/component/Modal/ModalTypePromocode/ModalEditType.vue'
  import ModalTypePromo from '@/views/component/Modal/ModalTypePromocode/ModalTypePromo.vue'
  import VueContext from 'vue-context'
  import ModalAddType from '@/views/component/Modal/ModalTypePromocode/ModalAddType.vue'
  import ModalBlockType from '@/views/component/Modal/ModalTypePromocode/ModalBlockType.vue'
export default {
  components:{
    VueContext,
    ModalTypePromo,
    ModalEditType,
    ModalAddType,
    ModalBlockType
  },
  data(){
    return{
      showPreloader:false,
      item: [
        {
          category_id: 1,
          1 : 1
        }
      ],
          field: [
          {key: 'checkbox', label: '', thStyle: {width: '30px'}},
          {key: 'category_id', label: 'ID', sortable: true, thStyle: {width: '50px'}},
          {key: '1', label: 'Наименование', sortable: true, thStyle: {width: '50px'}},
          {key: '2', label: 'Сумма', sortable: true, thStyle: {width: '50px'}},
          {key: '3', label: 'Сумма владельцу', sortable: true, thStyle: {width: '50px'}},
          {key: '4', label: 'Необходимая сумма заказов', sortable: true, thStyle: {width: '50px'}},
          {key: '5', label: 'Можно ли редактировать сумму', sortable: true, thStyle: {width: '50px'}},
          {key: '6', label: 'Условия активации', sortable: true, thStyle: {width: '50px'}},
          {key: '7', label: 'Бонусы', sortable: true, thStyle: {width: '50px'}},
          {key: '8', label: 'Скидка', sortable: true, thStyle: {width: '50px'}},
          {key: '9', label: 'Лимит заказов по скидке', sortable: true, thStyle: {width: '50px'}},
          {key: '10', label: 'Премиальная программа', sortable: true, thStyle: {width: '50px'}},
          {key: '11', label: 'Описание программы', sortable: true, thStyle: {width: '50px'}},
          {key: '12', label: 'Организация', sortable: true, thStyle: {width: '50px'}},
          {key: '12', label: 'Показывать в приложении', sortable: true, thStyle: {width: '50px'}},
          {key: '13', label: 'URL полное описание', sortable: true, thStyle: {width: '50px'}},
          {key: '14', label: 'Можно делиться (текст)', sortable: true, thStyle: {width: '50px'}},
          {key: '15', label: 'Активация оператором', sortable: true, thStyle: {width: '50px'}},
          {key: '16', label: 'Файлы ', sortable: true, thStyle: {width: '50px'}},
        ],
    }
  },
    methods:{
      handleContextMenuEvent(item, index, event){
        event.preventDefault();
        this.$refs.menu.open(event)
        this.id = item
      },
      openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.tariffs = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffs.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        }
    }
}
</script>

<style>

</style>